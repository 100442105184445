<template>
    <div>
        <iframe
            id="gameIframe"
            title="Game Server Iframe"
            :src="iframeSrc"
            class="iframe-box"
            allow="fullscreen;camera;xr-spatial-tracking"
            allowusermedia
        />
    </div>
</template>

<script>
    import challengeService from '@/services/challenge-service'
    import emitter from '@/utils/emitter'
    export default {
        name: 'Challenge',
        data() {
            return {
                locationPath: window.location.protocol + '//' + window.location.host
            }
        },
        computed: {
            challengeId() {
                return parseInt(this.$route.params.challengeId)
            },
            challengeSessionId() {
                return this.$store.getters.getChallengeSessionId
            },
            challenge() {
                return this.$store.getters.availableChallenges.find(challenge => challenge.id === this.challengeId)
            },
            lang() {
                return this.$store.getters.getLanguage
            },
            iframeSrc() {
                const gameId = this.challengeId
                if (!gameId) {
                    return null
                }
                if (this.locationPath === this.config.host) {
                    return `${this.config.gameServerHost}/gaiming/games/${gameId}?targetOrigin=${this.config.host}&lang=${this.lang}&isChallenge=true`
                } else {
                    return `${this.config.gameServerHost}/gaiming/games/${gameId}?targetOrigin=${this.locationPath}&apiHost=${this.config.host}&lang=${this.lang}&isChallenge=true`
                }
            },
            createChallengeSessionData() {
                return this.$store.getters.getCreateChallengeSessionData
            },
            joinerChallengerId() {
                return this.$store.getters.getJoinerChallengerId
            },
            challengeIdForChallengeSessionCreation() {
                return this.$store.getters.getChallengeId
            },
            isCreator() {
                return this.challengeIdForChallengeSessionCreation !== null
            }
        },
        created() {
            window.addEventListener('message', this.messageEventHandler)
        },
        beforeDestroy() {
            this.closeFrame()
            window.removeEventListener('message', this.messageEventHandler)
        },
        mounted() {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            window.addEventListener('resize', () => {
                const vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            })

            const element = document.getElementById('gameIframe')
            element.scrollIntoView({ block: 'end', inline: 'nearest' })
        },
        methods: {
            messageEventHandler(event) {
                if (!this.config.gameServerHost) {
                    return
                }
                const json = event.data
                if (!this.challengeId) {
                    return
                }
                const challengeData = {
                    sessionId: this.challengeSessionId,
                    points: 0
                }
                if (json.action === 'finish') {
                    this.finishSession({ ...challengeData, points: json.score })
                } else if (json.action === 'start' && this.isCreator) {
                    this.startSessionAsCreator()
                } else if (json.action === 'start') {
                    this.startSessionAsJoiner(challengeData)
                } else if (json.action === 'close') {
                    this.closeFrame()
                }
            },
            finishSession(challengeDataAndPoints) {
                challengeService.challengeSessionFinish(challengeDataAndPoints).then((res) => {
                    emitter.emit('openChallengeEndModal', res.data)
                })
                this.$router.push('/challenges')
            },
            startSessionAsCreator() {
                challengeService.createChallengeSession({
                    challengeId: this.challengeIdForChallengeSessionCreation,
                    joinerChallengerId: this.joinerChallengerId
                }).then(response => {
                    this.$store.dispatch('setChallengeSessionId', response.data)
                }).catch(e => {
                    this.displayToast(e)
                    this.$router.push({ name: 'Challenges' })
                }).finally(() => {
                    this.$store.dispatch('setCreateChallengeSessionData', {
                        challengeId: null,
                        joinerChallengerId: null
                    })
                })
            },
            startSessionAsJoiner(challengeSessionData) {
                challengeService.challengeSessionStart(challengeSessionData).catch(e => {
                    this.displayToast(e)
                    this.$router.push({ name: 'Challenges' })
                })
            },
            closeFrame() {
                const frame = document.getElementById('gameIframe')
                if (frame) {
                    frame.remove()
                }
            }
        }
    }
</script>
<style scoped>
.iframe-box {
    border: none;
    height: 100vh;
    width: 100%;
}
</style>
